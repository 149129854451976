<template>
  <div id="rolechoose">

    <el-dialog title="途啦用户注册协议和隐私政策" :visible.sync="dialogVisible" width="800px"
               :show-close="false">
      <Protocol/>
      <span slot="footer" class="dialog-footer">
        <el-button><router-link :to="{ name: 'index' }">取 消</router-link></el-button>
        <el-button type="primary" @click="dialogVisible = false">同意并继续</el-button>
      </span>
    </el-dialog>

    <div class="wrap">
      <div class="text">
        <span style="font-size: 28px; font-weight: bolder; margin-bottom: 25px"
          >欢迎注册</span
        >
        <span style="font-size: 20px; margin-bottom: 5px"
          >请选择您的注册角色</span
        >
        <span
          class="orange"
          style="font-size: 12px; margin-bottom: 50px; transform: scale(0.9)"
          >根据不同的角色须填写不同信息</span
        >
      </div>
      <div class="register">
        <div class="supplier" @click="routerJump('SupplierRegister')">
          <img class="img" src="../../assets/Login/sbg.png" />
          <div class="role co_fff">
            <img class="icon" src="../../assets/Login/supplier.png" />
            <span>供应商注册</span>
          </div>
        </div>
        <div class="purchaser" @click="routerJump('PurchaserRegister')">
          <img class="img" src="../../assets/Login/pbg.png" />
          <div class="role co_fff">
            <img class="icon" src="../../assets/Login/gouwuman.png" />
            <span>采购商注册</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Protocol from './Protocol'
export default {
  components:{Protocol},
  name: "RoleChoose",
  data() {
    return {
      dialogVisible: true
    }
  },
  methods: {
    // 路由跳转
    routerJump(routerName) {
      this.$router.push({
        name: routerName
      })
    }
	}
};
</script>
<style lang="less" scoped>
#rolechoose {
  width: 100%;
  height: 100vh;
  background: url("../../assets/Login/BG.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrap {
    text-align: center;
    display: inline-block;
    .text {
      text-align: center;
      span {
        display: block;
      }
    }
    .register {
      .purchaser,
      .supplier {
        display: inline-block;
        position: relative;
				cursor: pointer;
        .img {
          height: 250px;
          width: 450px;
        }
        .role {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /* text-align: center; */
          .icon {
            display: inline-block;
          }
          span {
            font-size: 19px;
            display: block;
          }
        }
      }
      .purchaser {
        margin-left: 70px;
      }
    }
  }
}
</style>
